import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorState, Paths, RequestData, ResponseData } from '../hooks';

export const useUpdate = <D extends RequestData, T extends ResponseData>(
  path: Paths,
  id?: string
) => {
  const url = `${process.env.REACT_APP_API_URL}${path}/${id}`;

  const navigate = useNavigate();
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<ErrorState>(null);
  const [loading, setLoading] = useState(false);

  const updateData = async (data: D) => {
    try {
      setLoading(true);

      const headers = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.jwt}`,
        },
        body: JSON.stringify(data),
      };

      const response = await fetch(url, headers);

      if (response.status === 401) {
        navigate('/sign-in');
        return;
      } else if (!response.ok) {
        const error = `${response.status} ${response.statusText}`;
        throw new Error(error);
      }

      const responseData: T = await response.json();
      setData(responseData);

      return responseData;
    } catch (err) {
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { data, error, loading, updateData };
};
