import { TerritoryFields, TerritoryData } from '../types';

export const territoryFormInitialValues: TerritoryData = {
  [TerritoryFields.UserID]: '',
  [TerritoryFields.Code]: '',
  [TerritoryFields.Street]: '',
  [TerritoryFields.House]: '',
  [TerritoryFields.Entrance]: '',
  [TerritoryFields.Comment]: '',
  [TerritoryFields.Area]: null,
  [TerritoryFields.Map]: '',
  [TerritoryFields.StartAt]: '',
  [TerritoryFields.FinishAt]: '',
  [TerritoryFields.Zoom]: 18,
};
