import React, { useState, useEffect, useRef } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import ErrorDialog from '../common/error-dialog';
import Loading from '../common/loading';
import BackDrop from '../common/backdrop';
import Snackbar from '../common/snackbar';
import { parseJwt } from '../common/parseJwt';

import { useTranslation } from 'react-i18next';

const udata = parseJwt(localStorage.jwt);

let oldData = [];

function ChangeUsername() {
  const { t } = useTranslation(); // i18n

  const [username, changeUsername] = useState('');
  const [usernameValidationErr, setUsernameValidationErr] = useState(null);
  const [confirmationCode, setConfirmationCode] = useState('');
  const [confirmationCodeSent, setConfirmationCodeSent] = useState(false);
  const [confirmationCodeValidationErr, setConfirmationCodeValidationErr] = useState(null);
  const [dataEdited, setDataEdited] = useState(false);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState(false);
  const [data, setData] = useState(null);
  const [step, setStep] = useState(1); // Step 1: Enter email, Step 2: Enter confirmation code

  const usernameInputRef = useRef(null);
  const confirmationCodeInputRef = useRef(null);

  useEffect(() => {
    getData(udata.id);

    const timeoutId = setTimeout(() => {
      if (usernameInputRef.current) {
        // console.log('usernameInputRef.current:', usernameInputRef.current); // Check inside timeout
        usernameInputRef.current.focus();
      }
    }, 500); // Minimal delay, but enough in most cases
  
    return () => clearTimeout(timeoutId); // Cleanup timeout on unmount    
  }, []);

  useEffect(() => {
    const isEqual = JSON.stringify(oldData) === JSON.stringify([username]);
    if (!isEqual) {
      setDataEdited(true);
    } else {
      setDataEdited(false);
    }
  }, [username]);

  const handleUsernameChange = event => {
    if (usernameValidationErr) setUsernameValidationErr(null);
    changeUsername(event.target.value);
  };

  const handleConfirmationCodeChange = event => {
    if (confirmationCodeValidationErr) setConfirmationCodeValidationErr(null);
    setConfirmationCode(event.target.value);
  };

  const getData = async uid => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/users/${uid}`;
      const headers = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.jwt}`,
        },
      };

      await fetch(url, headers)
        .then(response => {
          if (response.status === 401) {
            window.location = '/sign-in';
          } else if (response.ok) {
            return response.json();
          } else {
            setError(`${response.status} ${response.statusText}`);
          }
        })
        .then(data => {
          oldData = [username];

          setData(data);
        })
        .catch(err => {
          console.error(err);
          setError(t('Sorry! Failed to connect server =('));
        });
    } catch (error) {
      setError(error);
    }
  };

  const validateUsername = (username) => {
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,8}$/;
    const invisibleCharRegex = /[\s]/; // Regex to check for invisible characters (whitespace)

    if (!emailRegex.test(username)) {
      return { isValid: false, message: 'InvalidEmailFormat' };
    }
    if (invisibleCharRegex.test(username)) {
      return { isValid: false, message: 'PwdNoInvisibleChars' };
    }

    return { isValid: true, message: '' };
  };

  const sendConfirmationCode = async () => {
    const validationResult = validateUsername(username);
    if (!validationResult.isValid) {
// console.log('validationResult:', validationResult);      
      setUsernameValidationErr(t(validationResult.message));
      return;
    }

    try {
      const url = `${process.env.REACT_APP_API_URL}/send-confirmation-code`;
      const body = {
        email: username,
      };
      const request = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.jwt}`,
        },
        body: JSON.stringify(body),
      };

      await fetch(url, request)
        .then(response => {
          if (response.status === 401) {
            window.location = '/sign-in';
          } else if (response.ok) {
            setConfirmationCodeSent(true);
            setStep(2); // Move to step 2
            if (confirmationCodeInputRef.current) {
              confirmationCodeInputRef.current.focus();
            }
          } else {
            setError(`${response.status} ${response.statusText}`);
          }
        })
        .catch(err => {
          console.error(err);
          setError(t('Sorry! Failed to connect server =('));
        });
    } catch (error) {
      setError(error);
    }
  };

  const confirm = async () => {
    setBackdropOpen(true);

    let errors = {
      username: usernameValidationErr,
      confirmationCode: confirmationCodeValidationErr,
    };

    // console.log('errors:', errors);
    // console.log('username:', username);

    if (username.length > 0) {
      const validationResult = validateUsername(username);
      if (!validationResult.isValid) {
        errors.username = t(validationResult.message);
        // console.log('--errors.username:', errors.username);
      }
    } else {
      errors.username = null;
    }

    if (confirmationCode.length === 0) {
      errors.confirmationCode = t('Confirmation code is required');
    }

    if (errors.username || errors.confirmationCode) {
      setBackdropOpen(false);
      setUsernameValidationErr(errors.username);
      setConfirmationCodeValidationErr(errors.confirmationCode);
      return;
    }

    try {
      const url = `${process.env.REACT_APP_API_URL}/verify-confirmation-code`;
      const body = {
        email: username,
        code: confirmationCode,
      };
      const request = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.jwt}`,
        },
        body: JSON.stringify(body),
      };

      await fetch(url, request)
        .then(response => {
//  console.log('response:', response, response.status, response.statusText, response.ok, response.code);
          if (response.status === 401) {
            // console.log('response.status === 401');
            window.location = '/sign-in';
          } else if (response.ok) {
            // console.log('response.ok');
            return response.json();
          } else if (response.status === 400) {
            return response.json().then(data => {
              setConfirmationCodeValidationErr(t('InvalidConfirmationCode'));
              setBackdropOpen(false);
            });
          } else {
            setError(`${response.status} ${response.statusText}`);
            // console.log(`${response.status} ${response.statusText}`);
          }
        })
        .then(data => {
          if (data && data.status === 'success') {
            updateUsername();
          } else {
            setConfirmationCodeValidationErr(t('InvalidConfirmationCode'));
            setBackdropOpen(false);
          }
        })
        .catch(err => {
          console.error(err);
          setError(t('Sorry! Failed to connect server =('));
        });
    } catch (error) {
      setError(error);
    }
  };

  const updateUsername = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/users/${udata.id}`;
      const body = {
        username: username,
      };
      const request = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.jwt}`,
        },
        body: JSON.stringify(body),
      };

      await fetch(url, request)
        .then(response => {
// console.log('response:', response, response.status, response.statusText, response.ok);
          if (response.status === 401) {
            window.location = '/sign-in';
          } else if (response.ok) {
            oldData = [username];

            setDataEdited(false);
            setBackdropOpen(false);
            setSnackbar(true);
          }
        })
        .catch(err => {
          setError(t('Sorry! Failed to connect server =('));
        });
    } catch (error) {
      setError(error);
    }
  };

  const hideSnackbar = () => setSnackbar(false);

  if (error) return <ErrorDialog open={Boolean(error)} text={error} />;
  else if (!data) return <Loading />;
  return (
    <Grid container style={{ width: '100%', padding: '32px', margin: '0px' }}>
      <BackDrop open={backdropOpen} />
      <Snackbar
        type={'success'}
        msg={t('SuccessfullyUpdatedProfile')}
        open={snackbar}
        hideSnackbar={() => hideSnackbar()}
      />

      <Grid item xs={0} md={3} lg={4}></Grid>

      <Grid container item xs={12} md={6} lg={4}>
        <Paper elevation={5} style={{ width: '100%', padding: '12px', backgroundColor: '#FAFAFA' }}>
          <Grid item xs={12}>
            <Typography variant="h3" gutterBottom component="div" align="center">
              <AccountCircleIcon style={{ fontSize: '64px' }} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom component="div" align="center">
              {step === 1 ? t('EnterUsername') : t('EnterConfirmationCode')}
            </Typography>
          </Grid>

          {step === 1 && (
            <Grid item xs={12} style={{ paddingTop: '24px' }}>
              <TextField
                label={t('NewUsername')}
                variant="outlined"
                style={{ width: '100%' }}
                error={Boolean(usernameValidationErr)}
                helperText={usernameValidationErr}
                value={username}
                onChange={handleUsernameChange}
                inputRef={usernameInputRef}
              />
              <Button
                variant="contained"
                style={{ width: '100%', marginTop: '24px' }}
                onClick={sendConfirmationCode}
              >
                {t('VerifyEmail')}
              </Button>
            </Grid>
          )}

          {step === 2 && (
            <Grid item xs={12} style={{ paddingTop: '24px' }}>
              <TextField
                label={t('ConfirmationCode')}
                variant="outlined"
                style={{ width: '100%' }}
                error={Boolean(confirmationCodeValidationErr)}
                helperText={confirmationCodeValidationErr}
                value={confirmationCode}
                onChange={handleConfirmationCodeChange}
                inputRef={confirmationCodeInputRef}
              />
              <Button
                variant="contained"
                style={{ width: '100%', marginTop: '24px' }}
                onClick={confirm}
              >
                {t('ConfirmUsernameChange')}
              </Button>
            </Grid>
          )}

          <Grid item xs={12} style={{ paddingTop: '24px' }}>
            <Link href="/profile" variant="body2">
              {t('Edit Profile Data')}
            </Link>
          </Grid>

          <Grid item xs={12} style={{ paddingTop: '24px' }}>
            <Link href="/change-password" variant="body2">
              {t('Change password')}
            </Link>
          </Grid>

        </Paper>
      </Grid>
    </Grid>
  );
}

export default ChangeUsername;