import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';

import { StyledTableCell } from './StyledTableCell';
import { StyledTableRow } from './StyledTableRow';
import {
  Maybe,
  Scalars,
  TerritoryData,
  TerritoryFields,
  TerritoryHistoryData,
  TerritoryHistoryFields,
  UserFields,
  UserPermissions,
} from '../../types';
import { useOne, usePublishers } from '../../hooks';
import { isPermissionsIncludes, toUpperCaseFirst } from '../../helpers';
import { ButtonDeleteTerritoryHistoryRecord } from '../../components/ButtonDeleteTerritoryHistoryRecord';

type Props = {
  territoryId?: string;
  rows?: number;
  territoryData: TerritoryData;
  setValue: (name: keyof TerritoryData, value?: Maybe<string | Date>) => void;
};

type Column = { label: string; align: 'inherit' | 'center' };

const isCanEdit =
  isPermissionsIncludes(UserPermissions.TerritoryEditor) ||
  isPermissionsIncludes(UserPermissions.TerritoryAssist);

const columns: Column[] = [
  { label: 'Publisher', align: 'inherit' },
  { label: 'start_at2', align: 'inherit' },
  { label: 'finish_at', align: 'inherit' },
];

if (isCanEdit) {
  columns.push({ label: 'deleteRecord', align: 'center' });
}

export const TerritoryHistory: FC<Props> = ({ territoryId, rows, territoryData, setValue }) => {
  const { t } = useTranslation();
  const { getPublisher } = usePublishers();

  const { data: territoryHistory, refetch: refetchTerritoryHistory } = useOne<
    TerritoryHistoryData[]
  >('/territoryhistory', territoryId);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rows ?? 5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const publisherFullName = (id: Maybe<Scalars['ID']>) => {
    const publisher = getPublisher(id);
    return publisher ? `${publisher[UserFields.LastName]} ${publisher[UserFields.FirstName]}` : '';
  };

  const sortedData = territoryHistory
    ?.sort(
      (a, b) =>
        new Date(b[TerritoryHistoryFields.FinishAt] ?? '').getTime() -
        new Date(a[TerritoryHistoryFields.FinishAt] ?? '').getTime()
    )
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const lastTerritoryHistoryRecord = sortedData?.at(1);
  const lastTerritoryData = {
    ...territoryData,
    [TerritoryFields.ID]: Number(territoryId),
    [TerritoryFields.UserID]: territoryData[TerritoryFields.UserID]
      ? territoryData[TerritoryFields.UserID]
      : null,
    [TerritoryFields.FinishAt]: lastTerritoryHistoryRecord?.[TerritoryHistoryFields.FinishAt] ?? '',
  };

  return (
    !!sortedData?.length && (
      <Stack mt={3} mb={1} gap={2}>
        <Typography variant="h6">{t('TerritoryHistory')}</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map(({ label, align }) => (
                  <StyledTableCell key={label} align={align}>
                    {t(label)}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map(row => {
                return (
                  <StyledTableRow key={row[TerritoryHistoryFields.ID]}>
                    <StyledTableCell>
                      {publisherFullName(row[TerritoryHistoryFields.UserID] ?? '')}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row[TerritoryHistoryFields.StartAt] as string}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row[TerritoryHistoryFields.FinishAt] as string}
                    </StyledTableCell>
                    {isCanEdit && (
                      <StyledTableCell align="center">
                        <ButtonDeleteTerritoryHistoryRecord
                          id={row[TerritoryHistoryFields.ID] ?? null}
                          territoryData={lastTerritoryData}
                          setValue={setValue}
                          refetch={refetchTerritoryHistory}
                        />
                      </StyledTableCell>
                    )}
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={sortedData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t('MUITablePaginationRowsPerPage')}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} ${t('MUITablePaginationDisplayRows')} ${count}`
          }
          getItemAriaLabel={type => t(`MUITablePagination${toUpperCaseFirst(type)}`)}
        />
      </Stack>
    )
  );
};
