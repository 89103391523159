import React, { useState } from 'react';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  // FormControl,
  // InputLabel,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { IMaskInput } from 'react-imask';
import { useTranslation } from 'react-i18next';
import ErrorDialog from '../../common/error-dialog';
import BackDrop from '../../common/backdrop';
import Snackbar from '../../common/snackbar';


// oldData = [];

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://jwhlp.com/">
        https://jwhlp.com/
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const PhoneNumberMask = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        '#': /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const theme = createTheme();

export default function SignUpCongr() {
  const { t } = useTranslation(); // i18n

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [congregation, setCongregation] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [snackbar, setSnackbar] = useState(false);

  const [firstNameValidationErr, setFirstNameValidationErr] = useState(null);
  const [lastNameValidationErr, setLastNameValidationErr] = useState(null);
  const [pnValidationErr, setPNValidationErr] = useState(null);
  const [congregationValidationErr, setCongregationValidationErr] = useState(null);
  const [emailValidationErr, setEmailValidationErr] = useState(null);
  const [messageValidationErr, setMessageValidationErr] = useState(null);

  const [backdropOpen, setBackdropOpen] = useState(false);
  const [error, setError] = useState(null);

  const hideSnackbar = () => setSnackbar(false);

  const handleFirstNameChange = (event) => {
    if (firstNameValidationErr) setFirstNameValidationErr(null);
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    if (lastNameValidationErr) setLastNameValidationErr(null);
    setLastName(event.target.value);
  };

  const handlePNChange = (event) => {
    if (pnValidationErr) setPNValidationErr(null);
    setPhoneNumber(event.target.value);
  };

  const handleCongregationChange = (event) => {
    if (congregationValidationErr) setCongregationValidationErr(null);
    setCongregation(event.target.value);
  };

  const handleEmailChange = (event) => {
    if (emailValidationErr) setEmailValidationErr(null);
    setEmail(event.target.value);
  };

  const handleMessageChange = (event) => {
    if (messageValidationErr) setMessageValidationErr(null);
    setMessage(event.target.value);
  };

  const handleSubmit = async () => {
    setBackdropOpen(true);

    let errors = {
      firstName: firstNameValidationErr,
      lastName: lastNameValidationErr,
      phoneNumber: pnValidationErr,
      congregation: congregationValidationErr,
      email: emailValidationErr,
      message: messageValidationErr,
    };
console.log(congregation, congregation.length);
    if (firstName.length === 0) errors.firstName = t('Please provide your first name.');
    else if (!/^.{2,30}$/.test(firstName)) errors.firstName = t('Allowed 2-30 symbols');

    if (lastName.length === 0) errors.lastName = t('Please provide your last name.');
    else if (!/^.{2,30}$/.test(lastName)) errors.lastName = t('Allowed 2-30 symbols');

    const digitOnlyPhoneNumber = phoneNumber.replace(/\D/g, ''); // Remove all non-digit characters
    if (digitOnlyPhoneNumber.length === 0) errors.phoneNumber = t('Please provide your phone number that connected with messenger');
    else if (!/^.{10,50}$/.test(digitOnlyPhoneNumber)) errors.phoneNumber = t('Allowed 10-50 symbols');
    console.log(phoneNumber, digitOnlyPhoneNumber, digitOnlyPhoneNumber.length);

    if (congregation.length === 0) errors.congregation = t('Please provide your congregation name');
    else if (!/^.{4,50}$/.test(congregation)) errors.congregation = t('Allowed 4-50 symbols');

    if (email.length === 0) errors.email = t('Please provide your email');
    else if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)
    ) {
      errors.email = t('InvalidEmailFormat');
    }

    if (message.length === 0) {
      errors.message = t('Please write some additional information about your congreagetion');
    }

    if (
      errors.firstName ||
      errors.lastName ||
      errors.phoneNumber ||
      errors.congregation ||
      errors.email ||
      errors.message
    ) {
      console.log(errors);
      setBackdropOpen(false);

      setFirstNameValidationErr(errors.firstName);
      setLastNameValidationErr(errors.lastName);
      setPNValidationErr(errors.phoneNumber);
      setCongregationValidationErr(errors.congregation);
      setEmailValidationErr(errors.email);
      setMessageValidationErr(errors.message);

      return;
    }

    try {
      const url = `${process.env.REACT_APP_API_URL}/sign-up-congr`;
      console.log(url);
      const body = {
        firstName: firstName,
        lastName: lastName,
        phones: phoneNumber,
        congregation: congregation,
        username: email,
        message: message,
      };
      console.log(body);
      const request = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      };
      console.log(request);
      await fetch(url, request)
        .then((response) => {
          // if (response.ok) {
          //   setBackdropOpen(false);
          //   window.location = '/';
          // }
          if (response.status === 401) {
            window.location = '/sign-in';
          } else if (response.ok) {
            // oldData = [firstName, lastName, phoneNumber, congregation, email, message]; 

            // setDataEdited(false);
            setBackdropOpen(false);
            setSnackbar(true);
          }
        })
        .catch((err) => {
          setError(t('Sorry! Failed to connect server =('));
        });
    } catch (error) {
      setError(error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <BackDrop open={backdropOpen} />
      <ErrorDialog open={Boolean(error)} text={error} />
      <Snackbar
        type={'success'}
        msg={t('Thank you for contacting us!')}
        open={snackbar}
        hideSnackbar={() => hideSnackbar()}
      />

      <Grid item xs={0} md={3} lg={4}></Grid>

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('Sign-Up-Congregation')}
          </Typography>

          <Box onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoFocus
                  autoComplete="given-name"
                  fullWidth
                  label={t('First Name')}
                  error={Boolean(firstNameValidationErr)}
                  helperText={firstNameValidationErr}
                  value={firstName}
                  onChange={handleFirstNameChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  autoComplete="family-name"
                  label={t('Last Name')}
                  error={Boolean(lastNameValidationErr)}
                  helperText={lastNameValidationErr}
                  value={lastName}
                  onChange={handleLastNameChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={t('Phone Number')}
                  autoComplete="phone"
                  InputProps={{
                    inputComponent: PhoneNumberMask,
                  }}
                  error={Boolean(pnValidationErr)}
                  helperText={pnValidationErr}
                  value={phoneNumber}
                  onChange={handlePNChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t('Congregation name')}
                  autoComplete="congregation"
                  error={Boolean(congregationValidationErr)}
                  helperText={congregationValidationErr}
                  value={congregation}
                  onChange={handleCongregationChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t('Email Address')}
                  autoComplete="email"
                  error={Boolean(emailValidationErr)}
                  helperText={emailValidationErr ? emailValidationErr : t('EmailUsername')}
                  value={email}
                  onChange={handleEmailChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t('Message')}
                  type="message"
                  multiline
                  maxRows={500}
                  rows={5}
                  error={Boolean(messageValidationErr)}
                  helperText={messageValidationErr}
                  value={message}
                  onChange={handleMessageChange}
                />
              </Grid>
            </Grid>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSubmit}
            >
              {t('Send')}
            </Button>

            <Grid container justifyContent="flex-end">
              <Grid item xs>
                <Link href="/sign-in" variant="body2">
                  {t('Sign-In')}
                </Link>
              </Grid>
              <Grid item>
                <Link href="/sign-up" variant="body2">
                  {t('Sign-Up')}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}