import { useContext } from 'react';
import { SnackbarContext } from '../components';

export const useSnackbar = () => {
  const { openSnackbar, closeSnackbar } = useContext(SnackbarContext);

  return {
    openSnackbar,
    closeSnackbar,
  };
};
