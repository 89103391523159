import * as React from 'react';
import { useState } from 'react';

import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container,
} from '@mui/material';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import ErrorDialog from '../../common/error-dialog';
import BackDrop from '../../common/backdrop';

import { useTranslation } from 'react-i18next';

import { GoogleLogin } from '@react-oauth/google';
// import { GoogleLogin,useGoogleLogin } from '@react-oauth/google';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://jwhlp.com/">
        https://jwhlp.com/
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}

// function parseJwt (token) {
// 	if (token)
// 		try {
// 			var base64Url = token.split('.')[1];
// 			var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
// 			var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
// 				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
// 			}).join(''));

// 			return JSON.parse(jsonPayload);
// 		} catch (e) {
// 			console.error(e);
// 			return "";
// 		}

// 	return "";
// };

const theme = createTheme();

export default function SignIn() {
  const { t } = useTranslation(); // i18n

  localStorage.setItem('admin', false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [emailValidationErr, setEmailValidationErr] = useState(null);
  const [passwordlValidationErr, setPasswordValidationErr] = useState(null);

  const [backdropOpen, setBackdropOpen] = useState(false);
  const [error, setError] = useState(null);

  // const googleLogin = useGoogleLogin({
  //   onSuccess: codeResponse => {console.log("codeResponse: ", codeResponse);},
  //   flow: 'auth-code',
  //   onError:error => {
  //     console.error('Sign-in with Google Failed: ');
  //     setError('Sign-in with Google Failed')
  //   }}
  // );

  const handleEmailChange = event => {
    if (emailValidationErr) setEmailValidationErr(null);

    setEmail(event.target.value);
  };

  const handlePWChange = event => {
    if (passwordlValidationErr) setPasswordValidationErr(null);

    setPassword(event.target.value);
  };

  const getStands = async jwt => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/stands`;
      const headers = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      };

      await fetch(url, headers)
        .then(response => {
          // console.log("response:", response);
          if (response.ok) return response.json();
          else setError(response);
        })
        .then(data => {
          // console.log(data);
          if (data.status === 'error') {
            if (data.message === 'Not allowed for stand called getStands.') {
              setBackdropOpen(false);
              return (window.location = `${process.env.REACT_APP_URL}/users`);
            } else if (data.message.startsWith('There is no necessary permissions')) {
              setBackdropOpen(false);
              return (window.location = `${process.env.REACT_APP_URL}/waiting-room`);
            }
          }

          localStorage.setItem('stands', JSON.stringify(data));
          setBackdropOpen(false);
          window.location = '/stand';
        })
        .catch(err => {
          console.error(err);
          setError(err);
        });
    } catch (error) {
      setError(error);
    }
  };

  const handleSubmit = async () => {
    setBackdropOpen(true);

    let errors = {
      email: emailValidationErr,
      password: passwordlValidationErr,
    };

    if (email.length === 0) errors.email = t('Please provide your email');
    if (password.length === 0) errors.password = t('Please provide your password');

    if (errors.email || errors.password) {
      setBackdropOpen(false);

      setEmailValidationErr(errors.email);
      setPasswordValidationErr(errors.password);

      return;
    }

    try {
      const url = `${process.env.REACT_APP_API_URL}/login`;
      const headers = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: email, password: password }),
      };

      await fetch(url, headers)
        .then(response => {
          // console.log("response", response);
          if (response.ok) {
            return response.json();
          } else {
            if (response.status === 401) return response.json();
          }
        })
        .then(data => {
          // console.log("response.data", data);
          if (data.status === 'error' && data.message === 'Incorrect Password') {
            setBackdropOpen(false);
            const errText = t('Wrong password!');
            setPasswordValidationErr(errText);
          } else if (
            data.status === 'error' &&
            (data.message === 'Username is wrong' || data.message === 'GUID is wrong')
          ) {
            setBackdropOpen(false);
            const errText = t('Wrong email!');
            setEmailValidationErr(errText);
          } else {
            const jwtData = data.token;
            localStorage.setItem('jwt', jwtData);

            getStands(jwtData);
          }
        })
        .catch(err => {
          console.error(err);
          setError(err);
        });
    } catch (error) {
      setError(error);
    }
  };

  // const handleSubmitSignInWithGoogle = async () => {
  //   console.log("googleLogin: ",googleLogin);
  // };

  return (
    <ThemeProvider theme={theme}>
      <BackDrop open={backdropOpen} />
      <ErrorDialog open={Boolean(error)} text={error} />

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('Sign-In')}
          </Typography>
          <Box sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              fullWidth
              label="Email"
              autoComplete="email"
              autoFocus
              error={Boolean(emailValidationErr)}
              helperText={emailValidationErr}
              value={email}
              onChange={handleEmailChange}
            />
            <TextField
              margin="normal"
              fullWidth
              label={t('Password')}
              type="password"
              autoComplete="current-password"
              error={Boolean(passwordlValidationErr)}
              helperText={passwordlValidationErr}
              value={password}
              onChange={handlePWChange}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSubmit}
            >
              {t('Sign-In')}
            </Button>

            {/* <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}

              onClick={handleSubmitSignInWithGoogle}
            >
              {t("Sign-In-With-Google")}
            </Button> */}

            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justify="center"
              sx={{ mt: 1, mb: 2 }}
              // style={{ minHeight: '100vh' }}
            >
              <Grid item xs={4}>
                <GoogleLogin
                  useOneTap
                  type="standard"
                  size="large"
                  theme="filled_black"
                  shape="rectangular"
                  width={320}
                  onSuccess={async credentialResponse => {
                    //                     console.log("credentialResponse: ", credentialResponse);
                    //                     const parsedJWT = parseJwt(credentialResponse.credential);
                    // console.log("parsedJWT=", parsedJWT);
                    try {
                      const url = `${process.env.REACT_APP_API_URL}/google`;
                      const headers = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                          clientId: credentialResponse.clientId,
                          credential: credentialResponse.credential,
                          select_by: credentialResponse.select_by,
                        }),
                      };

                      await fetch(url, headers)
                        .then(response => {
                          // console.log("response", response);
                          // if (response.ok) {
                          return response.json();
                          // } else {
                          //   if (response.status === 401) return response.json()
                          // }
                        })
                        .then(data => {
                          // console.log("response.data", data);
                          if (data.status === 'error') {
                            setBackdropOpen(false);
                            const errText = t(data.message);
                            setPasswordValidationErr(errText);
                            // } else if (data.status === "error" && (data.message === "Can't find this user")) {
                            //   setBackdropOpen(false);
                            //   const errText = t("Wrong email!");
                            //   setEmailValidationErr(errText);
                          } else {
                            const jwtData = data.token;
                            localStorage.setItem('jwt', jwtData);

                            getStands(jwtData);
                          }
                        })
                        .catch(err => {
                          console.error(err);
                          setError(err);
                        });
                    } catch (error) {
                      setError(error);
                    }
                  }}
                  onError={e => {
                    console.error('Sign-in with Google Failed: ', e);
                    setError('Sign-in with Google Failed');
                  }}
                />
              </Grid>
            </Grid>
            {/* <Grid container>
              <Grid>
                <Link href="/sign-up" variant="body2">
                  {t("Don't have an account? Sign-Up")}
                </Link>
              </Grid>
            </Grid> */}
              <Grid item xs={12}>
                <Link href="/sign-up-congregation" variant="body2">
                  {t('Sign-Up-Congregation')}
                </Link>
              </Grid>
              {/* <Grid item xs={12}>
                <Link href="/sign-in" variant="body2">
                  {t('Already have an account? Sign in')}
                </Link>
              </Grid> */}

              <Grid item xs={12}>
                <Link href="/contact-devs" variant="body2">
                  {t('Contact Developers')}
                </Link>
              </Grid>            
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
