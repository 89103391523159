import { getJwtData, getLockTerritoryDate, isPermissionsIncludes } from '../helpers';
import { IconsNames } from '../legos/Icon';
import { Maybe, Scalars, TerritoryData, TerritoryFields, UserPermissions } from '../types';

const { id: authUserID } = getJwtData();
const isUserPermissionsTerritoryEditorOrAssist =
  isPermissionsIncludes(UserPermissions.TerritoryEditor) ||
  isPermissionsIncludes(UserPermissions.TerritoryAssist);

export type TerritoryFilterData = {
  value: string;
  eq: (territory: TerritoryData, favorite?: Array<Maybe<Scalars['ID']> | undefined>) => boolean;
  sort: (a: TerritoryData, b: TerritoryData) => number;
  icon?: IconsNames;
};

export const territoryTableFilter: TerritoryFilterData[] = [
  {
    value: 'my_territories',
    eq: territory => territory[TerritoryFields.UserID] === authUserID,
    sort: (a: TerritoryData, b: TerritoryData) =>
      new Date(a[TerritoryFields.StartAt] ?? '').getTime() -
      new Date(b[TerritoryFields.StartAt] ?? '').getTime(),
  },
  {
    value: 'free',
    eq: territory => {
      const finishDate = new Date(territory[TerritoryFields.FinishAt] ?? '');
      const { isAfterLockDate } = getLockTerritoryDate(finishDate);

      return (
        !territory[TerritoryFields.UserID] &&
        !territory[TerritoryFields.StartAt] &&
        !(isAfterLockDate && !isUserPermissionsTerritoryEditorOrAssist)
      );
    },
    sort: (a: TerritoryData, b: TerritoryData) =>
      new Date(a[TerritoryFields.FinishAt] ?? '').getTime() -
      new Date(b[TerritoryFields.FinishAt] ?? '').getTime(),
  },
];

if (isUserPermissionsTerritoryEditorOrAssist) {
  territoryTableFilter.push({
    value: 'processed',
    eq: territory => !!territory[TerritoryFields.UserID] && !!territory[TerritoryFields.StartAt],
    sort: (a: TerritoryData, b: TerritoryData) =>
      new Date(a[TerritoryFields.StartAt] ?? '').getTime() -
      new Date(b[TerritoryFields.StartAt] ?? '').getTime(),
  });
}

if (isUserPermissionsTerritoryEditorOrAssist) {
  territoryTableFilter.push({
    value: 'allTerritoriesWithComments',
    eq: territory => !!territory[TerritoryFields.Comment],
    sort: (a: TerritoryData, b: TerritoryData) =>
      parseInt(a[TerritoryFields.Code] ?? '0', 10) - parseInt(b[TerritoryFields.Code] ?? '0', 10),
  });
}

territoryTableFilter.push({
  value: 'favorite',
  eq: (territory, favorite) => favorite?.includes(territory.id) ?? false,
  sort: (a: TerritoryData, b: TerritoryData) =>
    new Date(a[TerritoryFields.FinishAt] ?? a[TerritoryFields.StartAt] ?? '').getTime() -
    new Date(b[TerritoryFields.FinishAt] ?? b[TerritoryFields.StartAt] ?? '').getTime(),
  icon: 'favorite',
});
