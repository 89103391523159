export type Maybe<T> = T | null;

export type Scalars = {
  ID: number | string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Switch: 0 | 1;
  Date: string | Date;
  DateTime: string | Date;
};

export enum UserPermissions {
  Admin = 'admin',
  Members = 'members',
  StandAdmin = 'stand_admin',
  EditUsers = 'edit_users',
  CanLogin = 'can_login',
  TSReadOnly = 'TSReadOnly',
  TSEditor = 'TSEditor',
  TSPart1Editor = 'TSPart1Editor',
  TSPart3Editor = 'TSPart3Editor',
  TSAttendantsEditor = 'TSAttendantsEditor',
  TSPart2Editor = 'TSPart2Editor',
  WTMeetingEditor = 'WTMeetingEditor',
  EditStands = 'edit_stands',
  EditCongregation = 'edit_congregation',
  NotApproved = 'not_approved',
  User = 'user',
  UsersView = 'users_view',
  UsersEdit = 'users_edit',
  UsersEditRoles = 'users_edit_roles',
  StandEdit = 'stand_edit',
  CongregationEdit = 'congregation_edit',
  TerritoryEditor = 'territory_editor',
  TerritoryAssist = 'territory_assist',
  TerritoryView = 'territory_view',
}

export enum UserFields {
  ID = 'id',
  Active = 'active',
  Address = 'address',
  Email = 'email',
  FirstName = 'firstName',
  LastName = 'lastName',
  Username = 'username',
  Phones = 'phones',
  Guid = 'guid',
  GroupLeaderID = 'groupLeader_id',
  PioneerID = 'pioneer_id',
  RankID = 'rank_id',

  IsAllowed4Stand = 'isAllowed4Stand',
  IsAssistant = 'isAssistant',
  IsAuxClass = 'isAuxClass',
  IsBibleReading = 'isBibleReading',
  IsBibleStudy = 'isBibleStudy',
  IsBrother = 'isBrother',
  IsCBSChairman = 'isCBSChairman',
  IsCBSReader = 'isCBSReader',
  IsChairman = 'isChairman',
  IsDiggingGems = 'isDiggingGems',
  IsEntranceGuard = 'isEntranceGuard',
  IsGroupLeader = 'isGroupLeader',
  IsInitialCall = 'isInitialCall',
  IsLiving1 = 'isLiving1',
  IsLiving2 = 'isLiving2',
  IsMainClass = 'isMainClass',
  IsMicrophoneSrvc = 'isMicrophoneSrvc',
  IsOther = 'isOther',
  IsPrayer = 'isPrayer',
  IsReturnVisit = 'isReturnVisit',
  IsSunChariman = 'isSunChariman',
  IsSunTalk = 'isSunTalk',
  IsTalk5m = 'isTalk5m',
  IsTreasureTalk = 'isTreasureTalk',
  IsUse4fillins = 'isUse4fillins',
  IsWT = 'isWT',
  IsWTReader = 'isWTReader',
}

export type UserData = {
  [UserFields.ID]?: Maybe<Scalars['ID']>;
  [UserFields.Active]?: Maybe<Scalars['Int']>;
  [UserFields.Address]?: Maybe<Scalars['String']>;
  [UserFields.Email]?: Maybe<Scalars['String']>;
  [UserFields.FirstName]?: Maybe<Scalars['String']>;
  [UserFields.LastName]?: Maybe<Scalars['String']>;
  [UserFields.Username]?: Maybe<Scalars['String']>;
  [UserFields.Phones]?: Maybe<Scalars['String']>;
  [UserFields.Guid]?: Maybe<Scalars['String']>;
  [UserFields.GroupLeaderID]?: Maybe<Scalars['ID']>;
  [UserFields.PioneerID]?: Maybe<Scalars['ID']>;
  [UserFields.RankID]?: Maybe<Scalars['ID']>;

  [UserFields.IsAllowed4Stand]?: Maybe<Scalars['Switch']>;
  [UserFields.IsAssistant]?: Maybe<Scalars['Switch']>;
  [UserFields.IsAuxClass]?: Maybe<Scalars['Switch']>;
  [UserFields.IsBibleReading]?: Maybe<Scalars['Switch']>;
  [UserFields.IsBibleStudy]?: Maybe<Scalars['Switch']>;
  [UserFields.IsBrother]?: Maybe<Scalars['Switch']>;
  [UserFields.IsCBSChairman]?: Maybe<Scalars['Switch']>;
  [UserFields.IsCBSReader]?: Maybe<Scalars['Switch']>;
  [UserFields.IsChairman]?: Maybe<Scalars['Switch']>;
  [UserFields.IsDiggingGems]?: Maybe<Scalars['Switch']>;
  [UserFields.IsEntranceGuard]?: Maybe<Scalars['Switch']>;
  [UserFields.IsGroupLeader]?: Maybe<Scalars['Switch']>;
  [UserFields.IsInitialCall]?: Maybe<Scalars['Switch']>;
  [UserFields.IsLiving1]?: Maybe<Scalars['Switch']>;
  [UserFields.IsLiving2]?: Maybe<Scalars['Switch']>;
  [UserFields.IsMainClass]?: Maybe<Scalars['Switch']>;
  [UserFields.IsMicrophoneSrvc]?: Maybe<Scalars['Switch']>;
  [UserFields.IsOther]?: Maybe<Scalars['Switch']>;
  [UserFields.IsPrayer]?: Maybe<Scalars['Switch']>;
  [UserFields.IsReturnVisit]?: Maybe<Scalars['Switch']>;
  [UserFields.IsSunChariman]?: Maybe<Scalars['Switch']>;
  [UserFields.IsSunTalk]?: Maybe<Scalars['Switch']>;
  [UserFields.IsTalk5m]?: Maybe<Scalars['Switch']>;
  [UserFields.IsTreasureTalk]?: Maybe<Scalars['Switch']>;
  [UserFields.IsUse4fillins]?: Maybe<Scalars['Switch']>;
  [UserFields.IsWT]?: Maybe<Scalars['Switch']>;
  [UserFields.IsWTReader]?: Maybe<Scalars['Switch']>;
};

export enum TerritoryFields {
  ID = 'id',
  CongregationID = 'congregation_id',
  Code = 'code',
  Street = 'street',
  House = 'house',
  Entrance = 'entrance',
  Comment = 'comment',
  Map = 'map',
  Area = 'area',
  UserID = 'user_id',
  StartAt = 'start_at',
  FinishAt = 'finish_at',
  Zoom = 'mapzoom',
}

type PolygonPoint = {
  x: number;
  y: number;
};

export type AreaPolygon = {
  lineStrings?: [
    {
      points?: PolygonPoint[];
    },
  ];
};

export type AreaArray = Array<Array<Array<string>>>;

export type TerritoryData = {
  [TerritoryFields.ID]?: Maybe<Scalars['ID']>;
  [TerritoryFields.CongregationID]?: Maybe<Scalars['ID']>;
  [TerritoryFields.Code]?: Maybe<Scalars['String']>;
  [TerritoryFields.Street]?: Maybe<Scalars['String']>;
  [TerritoryFields.House]?: Maybe<Scalars['String']>;
  [TerritoryFields.Entrance]?: Maybe<Scalars['String']>;
  [TerritoryFields.Comment]?: Maybe<Scalars['String']>;
  [TerritoryFields.Area]?: Maybe<AreaPolygon>;
  [TerritoryFields.Map]?: Maybe<Scalars['String']>;
  [TerritoryFields.UserID]?: Maybe<Scalars['ID']>;
  [TerritoryFields.StartAt]?: Maybe<Scalars['Date']>;
  [TerritoryFields.FinishAt]?: Maybe<Scalars['Date']>;
  [TerritoryFields.Zoom]?: Maybe<Scalars['Int']>;
};

export type TerritoryDataSend = Omit<TerritoryData, TerritoryFields.Area> & {
  [TerritoryFields.Area]: string | number;
};

export type TerritoryDataResponse = {
  status: Maybe<'success'>;
  lastInsertId: Maybe<Scalars['ID']>;
};

export enum TerritoryHistoryFields {
  ID = 'id',
  TerritoryID = 'territory_id',
  UserID = 'user_id',
  StartAt = 'start_at',
  FinishAt = 'finish_at',
}

export type TerritoryHistoryData = {
  [TerritoryHistoryFields.ID]?: Maybe<Scalars['ID']>;
  [TerritoryHistoryFields.TerritoryID]?: Maybe<Scalars['ID']>;
  [TerritoryHistoryFields.UserID]?: Maybe<Scalars['ID']>;
  [TerritoryHistoryFields.StartAt]?: Maybe<Scalars['Date']>;
  [TerritoryHistoryFields.FinishAt]?: Maybe<Scalars['Date']>;
};
