import React, { useState, useEffect, useRef } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
// import OutlinedInput from '@mui/material/OutlinedInput';
// import InputLabel from '@mui/material/InputLabel';
// import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
// import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
// import { IMaskInput } from 'react-imask';

import ErrorDialog from '../common/error-dialog';
import Loading from '../common/loading';
import BackDrop from '../common/backdrop';
import Snackbar from '../common/snackbar';
import { parseJwt } from '../common/parseJwt';

import { useTranslation } from 'react-i18next';



const udata = parseJwt(localStorage.jwt);

const lowercaseRegex = /(?=.*[а-яa-z])/;
const uppercaseRegex = /(?=.*[А-ЯA-Z])/;
const digitRegex = /(?=.*\d)/;
const invisibleCharRegex = /[\s]/; // Regex to check for invisible characters (whitespace)
// const specialCharRegex = /(?=.*[@$!%*#?&.])/;
// const lengthRegex = /.{12,}/;

let oldData = [];

function ChangePassword() {
  const { t } = useTranslation(); // i18n

  const [password, changePassword] = useState('');
  const [confirmPassword, changeConfirmPassword] = useState('');
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  // const [pnValidationErr, setPNValidationErr] = useState(null);
  // const [emailValidationErr, setEmailValidationErr] = useState(null);
  const [passwordValidationErr, setPasswordValidationErr] = useState(null);
  const [confirmPasswordValidationErr, setConfirmPasswordValidationErr] = useState(null);

  const [dataEdited, setDataEdited] = useState(false);

  const [backdropOpen, setBackdropOpen] = useState(false);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState(false);

  const [data, setData] = useState(null);

  const passwordInputRef = useRef(null);

  useEffect(() => {
    getData(udata.id);

    const timeoutId = setTimeout(() => {
      if (passwordInputRef.current) {
        // console.log('passwordInputRef.current:', passwordInputRef.current); // Check inside timeout
        passwordInputRef.current.focus();
      }
    }, 500); // Minimal delay, but enough in most cases
  
    return () => clearTimeout(timeoutId); // Cleanup timeout on unmount    
  }, []);

  useEffect(() => {
    const isEqual = JSON.stringify(oldData) === JSON.stringify([password]);
    if (!isEqual) {
      setDataEdited(true);
    } else {
      setDataEdited(false);
    }
  }, [password]);

  const handleClickShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };
  const handleClickShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = event => {
    if (passwordValidationErr) setPasswordValidationErr(null);
    changePassword(event.target.value);
  };
  const handleConfirmPasswordChange = event => {
    if (confirmPasswordValidationErr) setConfirmPasswordValidationErr(null);
    changeConfirmPassword(event.target.value);
  };


  const getData = async uid => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/users/${uid}`;
      const headers = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.jwt}`,
        },
      };

      await fetch(url, headers)
        .then(response => {
          if (response.status === 401) {
            window.location = '/sign-in';
          } else if (response.ok) {
            return response.json();
          } else {
            setError(`${response.status} ${response.statusText}`);
          }
        })
        .then(data => {
          oldData = [password];

          setData(data);
        })
        .catch(err => {
          console.error(err);
          setError(t('Sorry! Failed to connect server =('));
        });
    } catch (error) {
      setError(error);
    }
  };

  const validatePassword = (password) => {
    //const passwordRegex = /^(?=.*[А-ЯA-Z])(?=.*[а-яa-z])(?=.*\d)(?=.*[@$!%*_-#?&.])[A-Za-z\d@$!%*#?&.]{8,}$/;
  
    if (password.length < 12) {
      return { isValid: false, message: 'PwdAtLeastNNcharacters' };
    }
    if (invisibleCharRegex.test(password)) {
      return { isValid: false, message: 'PwdNoInvisibleChars' };
    }    
    if (!lowercaseRegex.test(password)) {
      return { isValid: false, message: 'Pwd1lowercase' };
    }
    if (!uppercaseRegex.test(password)) {
      return { isValid: false, message: 'Pwd1uppercase' };
    }
    if (!digitRegex.test(password)) {
      return { isValid: false, message: 'Pwd1digit' };
    }

    // if (!specialCharRegex.test(password)) {
    //   return { isValid: true, message: 'Pwd2specialCharacterReccomended' };
    // }
  
    return { isValid: true, message: '' };
  };

  const confirm = async () => {
    setBackdropOpen(true);

    let errors = {
      password: passwordValidationErr,
      confirmPassword: confirmPasswordValidationErr,
    };

    // console.log('errors:', errors);
    // console.log('password:', password);

    if (password.length > 0) {
      // console.log('password:', password, password.length);
      // if (!/^(?=.*[А-ЯA-Z])(?=.*[а-яa-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/.test(password)) {
      // if (!/^(?=.*[А-ЯA-Z])(?=.*[а-яa-z])(?=.*\d)(?=.*[@$%*#:;.!?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(password)) {
      //   errors.password = t('PasswordMinimumParams');
      //   console.log('--errors.password:', errors.password);
      // }
      const validationResult = validatePassword(password)
      if (!validationResult.isValid) {
        // errors.password = t('PasswordMinimumParams');
        errors.password = t(validatePassword(password).message);
        // console.log('--errors.password:', errors.password);
      } 

      if (confirmPassword !== password) {
        errors.confirmPassword = t('Passwords do not match');
      } else {
        errors.confirmPassword = null;
      }
    } else {
      errors.password = null;
      errors.confirmPassword = null;
    }

    if (
      errors.password ||
      errors.confirmPassword
    ) {
      setBackdropOpen(false);

      setPasswordValidationErr(errors.password);
      setConfirmPasswordValidationErr(errors.confirmPassword);

      return;
    }

    // firstName lastName phoneNumbers
    try {
      const url = `${process.env.REACT_APP_API_URL}/users/${udata.id}`;
      const body = {
        password: password,
      };
      const request = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.jwt}`,
        },
        body: JSON.stringify(body),
      };

      await fetch(url, request)
        .then(response => {
          if (response.status === 401) {
            window.location = '/sign-in';
          } else if (response.ok) {
            oldData = [password];

            setDataEdited(false);
            setBackdropOpen(false);
            setSnackbar(true);
          }
        })
        .catch(err => {
          setError(t('Sorry! Failed to connect server =('));
        });
    } catch (error) {
      setError(error);
    }
  };

  const hideSnackbar = () => setSnackbar(false);

  if (error) return <ErrorDialog open={Boolean(error)} text={error} />;
  else if (!data) return <Loading />;
  return (
    <Grid container style={{ width: '100%', padding: '32px', margin: '0px' }}>
      <BackDrop open={backdropOpen} />
      <Snackbar
        type={'success'}
        msg={'Successfuly updated profile data!'}
        open={snackbar}
        hideSnackbar={() => hideSnackbar()}
      />

      <Grid item xs={0} md={3} lg={4}></Grid>

      <Grid container item xs={12} md={6} lg={4}>
        <Paper elevation={5} style={{ width: '100%', padding: '12px', backgroundColor: '#FAFAFA' }}>
          <Grid item xs={12}>
            <Typography variant="h3" gutterBottom component="div" align="center">
              <AccountCircleIcon style={{ fontSize: '64px' }} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom component="div" align="center">
              {t('PasswordMinimumParams')}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: '24px' }}>
            <TextField
              label={t('New Password')}
              variant="outlined"
              style={{ width: '100%' }}
              type={showPassword1 ? 'text' : 'password'}
              error={Boolean(passwordValidationErr)}
              helperText={passwordValidationErr}
              value={password}
              onChange={handlePasswordChange}
              inputRef={passwordInputRef}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword1}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword1 ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}               
            />
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              paddingTop: '24px',
              display: password.length !== 0 ? 'block' : 'none',
            }}
          >
            <TextField
              label={t('ConfirmPassword')}
              variant="outlined"
              style={{ width: '100%' }}
              type={showPassword2 ? 'text' : 'password'}
              error={Boolean(confirmPasswordValidationErr)}
              helperText={confirmPasswordValidationErr}
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword2}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword2 ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}              
            />
          </Grid>

          <Grid container spacing={3} item xs={12} style={{ paddingTop: '48px' }}>
            <Grid item xs={0} md={6} lg={8}></Grid>

            <Grid item xs={12} md={6} lg={4}>
              <Button
                variant="contained"
                style={{ width: '100%' }}
                onClick={confirm}
                disabled={!dataEdited}
              >
                {t('Confirm')}
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ paddingTop: '24px' }}>
            <Link href="/profile" variant="body2">
              {t('Edit Profile Data')}
            </Link>
          </Grid>

          <Grid item xs={12} style={{ paddingTop: '24px' }}>
            <Link href="/change-username" variant="body2">
              {t('Change username')}
            </Link>
          </Grid>

        </Paper>
      </Grid>

      {/* <Grid item xs={0} md={3} lg={4}></Grid> */}
    </Grid>
  );
}

export default ChangePassword;
