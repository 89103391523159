import { addDays, isAfter } from 'date-fns';
import { getJwtData } from './getJwtData';

const { territory_hide_for_days: territoryHideForDays } = getJwtData();

export const getLockTerritoryDate = (date: Date) => {
  const today = new Date();
  const lockDate = addDays(date, territoryHideForDays ?? 0);
  const isAfterLockDate = isAfter(lockDate, today);

  return { lockDate, isAfterLockDate };
};
