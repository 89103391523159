import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  AppBar,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Switch,
  Toolbar,
  Typography,
} from '@mui/material';
import { AccountCircle, Language, Replay } from '@mui/icons-material';

import { getJwtData, isPermissionsIncludes } from '../../helpers';
import { UserPermissions } from '../../types';

import { userLogoutMenuItems, userMenuItems } from './constants';

export const NavBar = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(); // i18n
  const { permissions, isAllowed4Stand } = getJwtData();

  // Menu anchors
  const [anchorLangMenu, setAnchorLangMenu] = useState<HTMLButtonElement | null>(null);
  const [anchorProfileMenu, setAnchorProfileMenu] = useState<HTMLButtonElement | null>(null);

  // --- admin toggle --->
  const [checked, setChecked] = useState(!!JSON.parse(localStorage.admin ?? 'false'));

  const handleChange = (checked: boolean) => {
    if (isPermissionsIncludes(UserPermissions.StandAdmin)) {
      setChecked(checked);
      localStorage.setItem('admin', `${checked}`);
    } else setChecked(false);

    window.location.reload();
  };
  // --- --- --- --- ---

  const selectFromMenu = (href: string) => {
    setAnchorProfileMenu(null); // close menu
    navigate(href);
  };

  const selectLang = (lang: string) => {
    setAnchorLangMenu(null); // close menu
    document.documentElement.lang = lang;
    i18n.changeLanguage(lang);
    localStorage.setItem('lang', lang);
  };

  const logout = () => {
    localStorage.removeItem('jwt');
    localStorage.removeItem('stands');
    localStorage.removeItem('admin');
    navigate('/sign-in');
    window.location.reload();
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar
          style={{
            justifyContent: permissions?.length ? 'space-between' : 'end',
            display: 'flex',
          }}
        >
          {isPermissionsIncludes(UserPermissions.User) && !!isAllowed4Stand && (
            <Stack flexDirection="row" gap={2}>
              <Typography
                variant="h6"
                component="a"
                onClick={() => navigate('/stand')}
                sx={{
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  color: 'inherit',
                  textDecoration: 'none',
                  cursor: 'pointer',
                }}
              >
                {t('Stand')}
              </Typography>

              {isPermissionsIncludes(UserPermissions.StandAdmin) && (
                <Switch
                  checked={checked}
                  onChange={e => handleChange(e.target.checked)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              )}
            </Stack>
          )}

          <Stack flexDirection="row" gap={2}>
            <IconButton
              size="large"
              aria-haspopup="true"
              onClick={() => window.location.reload()}
              color="inherit"
              style={{ padding: '0px', margin: '0px' }}
            >
              <Replay style={{ fontSize: '32px' }} />
            </IconButton>

            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={e => setAnchorLangMenu(e.currentTarget)}
              color="inherit"
              style={{ padding: '0px', margin: '0px' }}
            >
              <Language style={{ fontSize: '32px' }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorLangMenu}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorLangMenu)}
              onClose={() => setAnchorLangMenu(null)}
              style={{ padding: '0px', margin: '0px' }}
            >
              <MenuItem onClick={() => selectLang('ua')}>Українська</MenuItem>
              <MenuItem onClick={() => selectLang('ru')}>Русский</MenuItem>
              <MenuItem onClick={() => selectLang('en')}>English</MenuItem>
            </Menu>

            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={e => setAnchorProfileMenu(e.currentTarget)}
              color="inherit"
              style={{ padding: '0px', margin: '0px' }}
            >
              <AccountCircle style={{ fontSize: '32px' }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorProfileMenu}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorProfileMenu)}
              onClose={() => setAnchorProfileMenu(null)}
              style={{ padding: '0px', margin: '0px' }}
            >
              {isPermissionsIncludes(UserPermissions.User) &&
                userMenuItems.map(({ path, translateString }) => (
                  <MenuItem key={path} onClick={() => selectFromMenu(path)}>
                    {t(translateString)}
                  </MenuItem>
                ))}

              {!!permissions?.length && (
                <MenuItem onClick={() => selectFromMenu('/territories')}>
                  {t('territories')}
                </MenuItem>
              )}

              {!!permissions?.length && (
                <MenuItem onClick={() => logout()}>{t('Sign-Out')}</MenuItem>
              )}

              {!permissions?.length &&
                userLogoutMenuItems.map(({ path, translateString }) => (
                  <MenuItem key={path} onClick={() => selectFromMenu(path)}>
                    {t(translateString)}
                  </MenuItem>
                ))}

              <MenuItem onClick={() => selectFromMenu('/contact-devs')}>
                {t('Contact Developers')}
              </MenuItem>
            </Menu>
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
