import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ClickAwayListener,
  IconButton,
  Paper,
  Popper,
  Stack,
  Typography,
} from '@mui/material';

import { Icon } from '../../legos';
import { useDelete, useSnackbar, useUpdate } from '../../hooks';
import { Maybe, Scalars, TerritoryData, TerritoryFields } from '../../types';

type Props = {
  id: Maybe<Scalars['ID']>;
  territoryData: TerritoryData;
  setValue?: (name: keyof TerritoryData, value?: Maybe<string | Date>) => void;
  refetch: () => Promise<void>;
};

export const ButtonDeleteTerritoryHistoryRecord: FC<Props> = ({
  id,
  territoryData,
  setValue,
  refetch,
}) => {
  const { t } = useTranslation();
  const { openSnackbar } = useSnackbar();

  const { updateData: updateTerritory } = useUpdate<TerritoryData, TerritoryData>(
    '/territories',
    `${territoryData[TerritoryFields.ID]}`
  );
  const { deleteData: deleteTerritoryHistoryRecord } = useDelete('/territoryhistory', `${id}`);

  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClickDeleteButton = (el: HTMLElement) => {
    setAnchorEl(el);
    setIsPopperOpen(true);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
    setIsPopperOpen(false);
  };

  const handleDeleteTerritoryHistoryRecord = () => {
    deleteTerritoryHistoryRecord()
      .then(() => {
        openSnackbar(t('DeleteTerritoryHistoryRecordSuccess'), 'success');
        refetch();

        if (!territoryData[TerritoryFields.UserID]) {
          updateTerritory(territoryData)
            .then(() => {
              if (setValue) {
                setValue(TerritoryFields.FinishAt, territoryData[TerritoryFields.FinishAt]);
              }
            })
            .catch(err => {
              openSnackbar(`${t('UpdateTerritoryError')}\n${err}`, 'error');
            });
        }
      })
      .catch(err => {
        openSnackbar(`${t('UpdateTerritoryError')}\n${err}`, 'error');
      })
      .finally(() => handleClickAway());
  };

  return (
    <>
      <IconButton
        color="error"
        size="small"
        onClick={e => handleClickDeleteButton(e.currentTarget)}
      >
        <Icon icon="delete" />
      </IconButton>

      {isPopperOpen && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Popper open={isPopperOpen} anchorEl={anchorEl}>
            <Paper elevation={6}>
              <Stack p={2} my={2} width={270}>
                <Typography mb={2} textAlign="center">
                  {t('AreYouSureDeleteHistoryRecord')}
                </Typography>
                <Stack gap={2} direction="row" justifyContent="center">
                  <Button size="small" variant="outlined" onClick={handleClickAway}>
                    {t('no')}
                  </Button>
                  <Button
                    size="small"
                    variant="outlined"
                    color="error"
                    onClick={handleDeleteTerritoryHistoryRecord}
                  >
                    {t('yes')}
                  </Button>
                </Stack>
              </Stack>
            </Paper>
          </Popper>
        </ClickAwayListener>
      )}
    </>
  );
};
