import React, { Fragment, useState } from 'react';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ButtonDeleteTerritoryHistoryRecord, MainWrapper } from '../components';
import { ChevronLeft, ChevronRight, Visibility } from '@mui/icons-material';
import { usePublishers, useTerritoriesHistory } from '../hooks';
import { Maybe, Scalars, TerritoryFields, TerritoryHistoryFields, UserFields } from '../types';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';

type TerritoryInfo = {
  id?: Maybe<Scalars['ID']>;
  territory_number?: Maybe<Scalars['ID']>;
};

export const TerritoriesHistory = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { territoriesHistory, refetch } = useTerritoriesHistory();
  const { getPublisher } = usePublishers();

  const [territoryInfo, setTerritoryInfo] = useState<TerritoryInfo>({
    id: null,
    territory_number: null,
  });

  const currentTerritoryIndex = territoriesHistory?.findIndex(({ id }) => id === territoryInfo.id);
  const currentTerritoryHistory = territoriesHistory?.find(({ id }) => id === territoryInfo.id);
  const lastTerritoryHistoryRecord = currentTerritoryHistory?.territory_history.at(1);

  const lastTerritoryData = {
    ...currentTerritoryHistory?.territory,
    [TerritoryFields.UserID]: currentTerritoryHistory?.territory[TerritoryFields.UserID]
      ? currentTerritoryHistory?.territory[TerritoryFields.UserID]
      : null,
    [TerritoryFields.StartAt]: currentTerritoryHistory?.territory[TerritoryFields.StartAt]
      ? currentTerritoryHistory?.territory[TerritoryFields.StartAt]
      : '',
    [TerritoryFields.FinishAt]: lastTerritoryHistoryRecord?.[TerritoryHistoryFields.FinishAt] ?? '',
  };

  const handleClick = ({ id, territory_number }: TerritoryInfo) => {
    setTerritoryInfo({ id, territory_number });
  };

  const handleClickPrev = () => {
    const t = territoriesHistory?.at((currentTerritoryIndex ?? 0) - 1);

    setTerritoryInfo({ id: t?.id, territory_number: t?.territory_number });
  };

  const handleClickNext = () => {
    const index = (currentTerritoryIndex ?? 0) + 1;
    const t = territoriesHistory?.at(index === territoriesHistory.length ? 0 : index);

    setTerritoryInfo({ id: t?.id, territory_number: t?.territory_number });
  };

  const columns = [
    {
      name: 'user_id',
      label: t('Publisher'),
      options: {
        filter: true,
        sort: true,
        display: true,
        customBodyRender: (id: Maybe<Scalars['ID']>) => {
          const publisher = getPublisher(id);
          return publisher
            ? `${publisher[UserFields.LastName]} ${publisher[UserFields.FirstName]}`
            : '';
        },
      },
    },
    {
      name: 'start_at',
      label: t('start_at2'),
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: 'finish_at',
      label: t('finish_at'),
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: 'id',
      label: ' ',
      options: {
        filter: true,
        sort: true,
        display: true,
        setCellProps: () => ({
          align: 'center',
          width: 50,
        }),
        customBodyRender: (id: Maybe<Scalars['ID']>) => {
          return (
            !!id && (
              <ButtonDeleteTerritoryHistoryRecord
                id={id}
                territoryData={lastTerritoryData}
                refetch={refetch}
              />
            )
          );
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    responsive: 'vertical',
    selectableRows: 'none',
    filterType: 'multiselect',
    searchOpen: false,
    textLabels: {
      pagination: {
        next: t('MUITablePaginationNext'),
        previous: t('MUITablePaginationPrevious'),
        rowsPerPage: t('MUITablePaginationRowsPerPage'),
        displayRows: t('MUITablePaginationDisplayRows'),
      },
    },
  };

  return (
    <MainWrapper>
      <Stack my={1} gap={2}>
        <Stack gap={1} direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">
            {t('territories')}: {t('TerritoryHistory')}
          </Typography>
          <Stack direction="row" gap={1}>
            <IconButton color="primary" sx={{ border: '1px solid' }} onClick={() => navigate(-1)}>
              <ChevronLeft />
            </IconButton>
            {!!territoryInfo.id && (
              <Link to={`/territory/${territoryInfo.id}`}>
                <IconButton color="primary" sx={{ border: '1px solid' }}>
                  <Visibility />
                </IconButton>
              </Link>
            )}
          </Stack>
        </Stack>

        {!!territoryInfo.id && (
          <Stack direction="row" gap={1}>
            <IconButton color="primary" sx={{ border: '1px solid' }} onClick={handleClickPrev}>
              <ChevronLeft />
            </IconButton>
            <IconButton color="primary" sx={{ border: '1px solid' }} onClick={handleClickNext}>
              <ChevronRight />
            </IconButton>
          </Stack>
        )}

        {!!territoryInfo.id &&
          territoriesHistory?.map(({ id, territory_history }) => (
            <Fragment key={id}>
              {territoryInfo.id === id && (
                <MUIDataTable
                  title={`${t('code')}: ${territoryInfo.territory_number}`}
                  data={territory_history}
                  columns={columns}
                  options={options}
                />
              )}
            </Fragment>
          ))}

        <Stack direction="row" gap={1} flexWrap="wrap">
          {territoriesHistory?.map(({ id, territory_number }) => (
            <Button
              key={id}
              variant={
                territoryInfo.territory_number === territory_number ? 'contained' : 'outlined'
              }
              onClick={() => handleClick({ id, territory_number })}
            >
              {territory_number}
            </Button>
          ))}
        </Stack>
      </Stack>
    </MainWrapper>
  );
};
