import React, { FormEvent } from 'react';
import { Stack } from '@mui/material';
import { MainWrapper, GoogleMap, TerritoryForm, TerritoryHistory } from '../components';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useFormData, useOne, useSnackbar, useUpdate, useValidateTerritoryForm } from '../hooks';
import { TerritoryFields, TerritoryData, TerritoryDataSend } from '../types';
import { territoryFormInitialValues } from '../constant';
import { getAreaPolygonArray, getPrepareTerritoryData } from '../helpers';

export const Territory = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { openSnackbar } = useSnackbar();

  const { data: territory } = useOne<TerritoryData>('/territories', id);
  const { loading, updateData: updateTerritory } = useUpdate<TerritoryDataSend, TerritoryData>(
    '/territories',
    id
  );

  const { values, errors, setValue, handleChange } = useFormData(
    territoryFormInitialValues,
    territory,
    useValidateTerritoryForm
  );

  const mapCoordinates: string[] | undefined = values[TerritoryFields.Map]?.split(/[ ,]+/);
  const mapLat = mapCoordinates?.at(0) ?? '';
  const mapLng = mapCoordinates?.at(1) ?? '';

  const isNotValidFormData = Boolean(Object.keys(errors).length);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isNotValidFormData) return;

    updateTerritory(getPrepareTerritoryData(values))
      .then(() => {
        openSnackbar(t('UpdateTerritorySuccess'), 'success');
      })
      .catch(err => {
        openSnackbar(`${t('UpdateTerritoryError')}\n${err}`, 'error');
      });
  };

  const markerInfo = `<p style="margin: 5px 0; font-size: 18px; text-align: center;"><b>${t(
    TerritoryFields.Street
  )}:</b> ${
    values[TerritoryFields.Street]
  }</p><p style="margin: 5px 0; font-size: 16px; text-align: center;">${
    values[TerritoryFields.House]
      ? t(TerritoryFields.House).toLowerCase().substring(0, 3) +
        '. ' +
        values[TerritoryFields.House]
      : ''
  }${
    values[TerritoryFields.Entrance]
      ? ', ' +
        t(TerritoryFields.Entrance).toLowerCase().substring(0, 3) +
        '. ' +
        values[TerritoryFields.Entrance]
      : ''
  }</p>`;

  return (
    territory && (
      <MainWrapper>
        <Stack my={1} gap={{ xs: 3, md: 4 }} direction={{ xs: 'column', md: 'row' }}>
          <Stack width="100%" order={{ xs: 1, md: 0 }}>
            <GoogleMap
              lat={+mapLat}
              lng={+mapLng}
              info={markerInfo}
              zoom={values[TerritoryFields.Zoom]}
              polygonCoordinates={getAreaPolygonArray(values[TerritoryFields.Area])}
            />
          </Stack>

          <TerritoryForm
            headerText={`${t('TerritoryEdit')} ${values[TerritoryFields.Code]}`}
            buttonText={t('Confirm')}
            loading={loading || isNotValidFormData}
            values={values}
            errors={errors}
            setValue={setValue}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
          />
        </Stack>

        <TerritoryHistory territoryId={id} territoryData={values} setValue={setValue} />
      </MainWrapper>
    )
  );
};
