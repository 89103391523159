import React from 'react';
import { Button } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '../../hooks';

interface CopyToClipboardButtonProps {
  text: string;
}

export const ButtonCopyToClipboard: React.FC<CopyToClipboardButtonProps> = ({ text }) => {
  const { t } = useTranslation();
  const { openSnackbar } = useSnackbar();

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        openSnackbar(t('TerritoryDataCopiedSuccess'), 'success');
      })
      .catch(error => {
        openSnackbar(`${t('TerritoryDataCopiedError')}\n${error}`, 'error');
      });
  };

  return (
    <Button onClick={handleCopyClick} variant="outlined">
      <ContentCopyIcon />
    </Button>
  );
};
